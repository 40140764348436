
import {Component, Prop, Vue} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TakipEntity} from "@/entity/TakipEntity";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {PersonelTuru} from "@/enum/PersonelTuru";
import {AdresTuru} from "@/enum/AdresTuru";

@Component({
  components: {FormWrapper}
})
export default class TahsilatMakbuzuTaslak extends Vue {
  @Prop() tahsilat!: TahsilatEntity;
  @Prop() takip!: TakipEntity;

  get alacaklilar() {
    if (!this.takip?.alacaklilar?.length) return '';
    
    let temp = "";
    this.takip.alacaklilar.forEach((alacakli: AlacakliEntity) => {
      if (alacakli?.muvekkil) {
        temp += this.$helper.kisiKurumAd(alacakli.muvekkil) + ", ";
      }
    })
    return temp.slice(0, -2);
  }

  get yetkiliAvukat() {
    const avukatlar = this.takip?.alacaklilar?.[0]?.muvekkil?.vekalet?.avukatlar;
    if (!avukatlar?.length) return null;

    const yetkili = avukatlar.find((avukat: any) => 
      avukat.personel_turu === PersonelTuru.kurucu_avukat || 
      avukat.personel_turu === PersonelTuru.idari_yonetici
    );

    return yetkili || avukatlar[0];
  }

  get makbuz() {
    if (!this.tahsilat || !this.takip) return '';

    try {
      const buro = this.yetkiliAvukat?.burolar?.[0];
      const adres = buro?.adres;
      
      let adresStr = '';
      if (adres) {
        adresStr = adres.adres_tur_id === AdresTuru.mernis ? 'Mernis Adresi' : (adres.adres ?? '');
      }

      return (buro?.isim || '') + "\n" +
        adresStr + "\n\n\n" +
        "TAHSİLAT MAKBUZU\n\n" +
        (this.takip.icra_dairesi?.isim || '') + "'nin, alacaklısı " + this.alacaklilar + " olan " +
        (this.takip.dosya_esas_no || '') + " sayılı dosyasına mahsuben, borçlu " + this.$helper.kisiKurumAd(this.tahsilat.borclu) +
        "'dan, yalnız " + this.$helper.newTutarStr(this.tahsilat.tutar) + " " + this.tahsilat.para_birimi.kisa_isim +
        " (" + this.$helper.tutarToText(this.tahsilat.tutar) + " " + this.tahsilat.para_birimi.kisa_isim + ") " +
        "tahsil edilmiştir " + this.$helper.tarihStr(this.tahsilat.tarih) + "\n\n" +
        "Alacaklı Vekili\n" +
        (this.yetkiliAvukat?.first_name || '') + " " + (this.yetkiliAvukat?.last_name || '') + " \t(Tahsilatı Yapan: " +
        (this.tahsilat.tahsilati_yapan || '') + ")";
    } catch (error) {
      console.warn('Makbuz oluşturulurken bir hata oluştu:', error);
      return 'Makbuz oluşturulurken bir hata oluştu';
    }
  }

  yazdir() {
    this.$nextTick(() => {
      try {
        this.$helper.printComponent('tahsilatMakbuzu');
      } catch (error) {
        console.error('Yazdırma hatası:', error);
      }
    });
  }
}
